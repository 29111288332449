<template>
  <v-flex
    class="recording-indicator"
    :class="{
      'recording-indicator--no-pointer': (game && game.stream) || !isHost
    }"
  >
    <template v-if="game && game.stream">
      <v-flex d-flex align-center>
        <v-icon
          :class="{ 'recording-indicator__icon--red': startingStream }"
          class="recording-indicator__icon"
        >
          fiber_manual_record
        </v-icon>
        <span class="recording-indicator__text">LIVE</span>
      </v-flex>
    </template>
    <template v-else>
      <v-tooltip right v-model="show" :close-delay="1500" :disabled="!isHost">
        <template v-slot:activator="{ on }">
          <v-flex
            d-flex
            align-center
            @mouseover="mouseover"
            @mouseleave="mouseleave"
            @click="click"
            v-on="on"
          >
            <v-icon
              class="recording-indicator__icon"
              :class="{ 'recording-indicator__icon--red': startingStream }"
            >
              fiber_manual_record
            </v-icon>
            <span class="recording-indicator__text">LIVE</span>
          </v-flex>
        </template>

        <span @mouseover="mouseover" @mouseleave="mouseleave" class="right">
          <template v-if="!!copiedText">
            {{ copiedText }}
          </template>
          <template v-else-if="!!broadcastUrl">
            <a class="recording-indicator__href" @click.prevent="copy">
              {{ broadcastUrl }}
            </a>
          </template>
          <template v-else>
            <a class="recording-indicator__href"> syncing with Twitch... </a>
          </template>
        </span>
      </v-tooltip>
    </template>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "RecordingIndicator",
  computed: {
    ...mapGetters({ game: "actualGame" }),
    ...mapGetters("auth", ["isHost"]),
    broadcastUrl() {
      return this.game.streamUrl
    }
  },
  data() {
    return {
      show: false,
      copiedText: null,
      hoverTimeout: null
    }
  },
  props: {
    startingStream: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(value) {
      if (!value) {
        setTimeout(() => {
          this.copiedText = null
        }, 300)
      }
    }
  },
  methods: {
    mouseover() {
      this.show = true
      clearTimeout(this.hoverTimeout)
    },
    mouseleave() {
      this.hoverTimeout = setTimeout(() => {
        this.show = false
      }, 1000)
    },
    click() {
      if (this.isHost) {
        const win = window.open(this.broadcastUrl, "_blank")
        win.focus()
      }
    },
    copy() {
      this.$clipboard(this.broadcastUrl)
      this.copiedText = "Copied!"
    }
  }
}
</script>

<style lang="scss">
.recording-indicator {
  @keyframes bounce {
    0% {
      text-shadow: 0px 0px 2px $color-green2, 0px 0px 2px rgba(0, 0, 0, 0.5);
      transform: scale(1);
    }
    50% {
      text-shadow: 0px 0px 4px $color-green2, 0px 0px 2px rgba(0, 0, 0, 0.5);
      transform: scale(1.1);
    }
    100% {
      text-shadow: 0px 0px 2px $color-green2, 0px 0px 2px rgba(0, 0, 0, 0.5);
      transform: scale(1);
    }
  }

  .v-tooltip__content {
    pointer-events: auto;
  }

  cursor: pointer;
  user-select: none;

  &__href {
    text-decoration: underline;
    color: #fff;
  }

  &__icon {
    color: $color-green2 !important;
    font-size: 25px;
    animation-name: bounce;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    &--red {
      color: red !important;
    }
  }
  &__text {
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.05em;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  }

  &--no-pointer {
    cursor: initial;
  }
}
</style>
