var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      staticClass: "recording-indicator",
      class: {
        "recording-indicator--no-pointer":
          (_vm.game && _vm.game.stream) || !_vm.isHost,
      },
    },
    [
      _vm.game && _vm.game.stream
        ? [
            _c(
              "v-flex",
              { attrs: { "d-flex": "", "align-center": "" } },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "recording-indicator__icon",
                    class: {
                      "recording-indicator__icon--red": _vm.startingStream,
                    },
                  },
                  [_vm._v(" fiber_manual_record ")]
                ),
                _c("span", { staticClass: "recording-indicator__text" }, [
                  _vm._v("LIVE"),
                ]),
              ],
              1
            ),
          ]
        : [
            _c(
              "v-tooltip",
              {
                attrs: {
                  right: "",
                  "close-delay": 1500,
                  disabled: !_vm.isHost,
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-flex",
                          _vm._g(
                            {
                              attrs: { "d-flex": "", "align-center": "" },
                              on: {
                                mouseover: _vm.mouseover,
                                mouseleave: _vm.mouseleave,
                                click: _vm.click,
                              },
                            },
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "recording-indicator__icon",
                                class: {
                                  "recording-indicator__icon--red":
                                    _vm.startingStream,
                                },
                              },
                              [_vm._v(" fiber_manual_record ")]
                            ),
                            _c(
                              "span",
                              { staticClass: "recording-indicator__text" },
                              [_vm._v("LIVE")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.show,
                  callback: function ($$v) {
                    _vm.show = $$v
                  },
                  expression: "show",
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "right",
                    on: {
                      mouseover: _vm.mouseover,
                      mouseleave: _vm.mouseleave,
                    },
                  },
                  [
                    !!_vm.copiedText
                      ? [_vm._v(" " + _vm._s(_vm.copiedText) + " ")]
                      : !!_vm.broadcastUrl
                      ? [
                          _c(
                            "a",
                            {
                              staticClass: "recording-indicator__href",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.copy.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.broadcastUrl) + " ")]
                          ),
                        ]
                      : [
                          _c(
                            "a",
                            { staticClass: "recording-indicator__href" },
                            [_vm._v(" syncing with Twitch... ")]
                          ),
                        ],
                  ],
                  2
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }